import get from 'lodash/get';
import React from 'react';
import { AheadHeader } from '../../ahead/ahead-header';
import returnNull from '../../atoms/null';
import { StoryPage } from '../../containers/story/story-page';
import RowWrapper from '../../row/row-wrapper';
import { COLLECTION_DATA } from '../../static-data/collection-data';
import {
  COLLECTION_OF_COLLECTIONS_DATA,
  COLLECTION_OF_COLLECTIONS_OF_STORIES_DATA
} from '../../static-data/collection-of-collections-data';
import {
  AUTHOR_DATA,
  AUTHORS_DATA,
  MAGAZINE_INTRO_DATA,
  TAG_DATA,
  SUBSCRIPTION_DATA
} from '../../static-data/intro-card-data';
import { MAGAZINE_PAGE_DATA } from '../../static-data/magazine-page-data';
import { SEARCH_DATA } from '../../static-data/search-page-data';
import { IComponentMeta, ISelectedRow, Window } from '../../utils/interfaces';
import { getStoryName } from '../../utils/utils';
import AdComponent from '../ad';
import { Login } from '../login';
import { Subscription } from '../subscription';
import { ManageSubscriptionAndPlans } from '../manage-subscription-and-plan';
import WidgetComponent from '../widget-component';
import styles from './loader-switch.module.css';
import { Profile } from '../profile-page';
import { PurchasedStoryPage } from '../purchased-story-page';

declare var window: Window;

interface IRowsGeneralConfig {
  [key: string]: string | object;
}

interface LoaderSwitchProps {
  currentRow: ISelectedRow;
  index: number;
  isHeaderRow?: boolean;
  rowsGeneralConfig?: IRowsGeneralConfig;
  componentMeta?: IComponentMeta[];
  backgroundColor?: string;
  pageType?: string;
  subPageType?: string;
  builderConfig?: object;
  configPath?: any;
  enableDarkModePreview: boolean;
}

interface SliderProps {
  ArrowFourStorySlider: boolean;
  ArrowHalfScreenSlider: boolean;
  ArrowFullScreenSlider: boolean;
  [key: string]: string | boolean;
}

export const loaderSwitch = ({
  currentRow,
  index,
  isHeaderRow,
  rowsGeneralConfig = {},
  componentMeta = [],
  backgroundColor,
  pageType,
  subPageType,
  builderConfig = {},
  configPath,
  enableDarkModePreview
}: LoaderSwitchProps) => {
  const collectionOfCollections = ['SliderCollectionOfCollections', 'ArrowCollectionFilter'];
  const associatedMetadata = Object.assign({}, { ...COLLECTION_DATA['associated-metadata'] });
  const { type, layout } = currentRow;
  const currentRowConfig = componentMeta.find(item => item.componentName === currentRow.layout);

  const defaultLoadCount = get(currentRowConfig, ['storiesCount'], null);
  let initialLoadCount = get(currentRow, ['config', 'initialLoadCount']) || defaultLoadCount;
  const sliderComponents: SliderProps = {
    ArrowFourStorySlider: true,
    ArrowHalfScreenSlider: true,
    ArrowFullScreenSlider: true
  };

  if (sliderComponents[currentRow.layout]) {
    initialLoadCount = get(currentRow, ['config', 'numberOfStoriesToShow']) || defaultLoadCount;
  }

  const getFixtureData = (row: any, layoutName: string) => {
    if (
      layoutName === 'ArrowFourColSixteenStories' ||
      layoutName === 'ArrowTwoColTenStoriesSidebar' ||
      layoutName === 'ArrowThreeColTwelveStories' ||
      layoutName === 'ArrowAstrologyCollection'
    ) {
      return COLLECTION_OF_COLLECTIONS_OF_STORIES_DATA;
    } else if (collectionOfCollections.includes(layoutName)) {
      return COLLECTION_OF_COLLECTIONS_DATA;
    } else {
      let collectionData: any = { 'associated-metadata': {} };
      collectionData['associated-metadata'] = Object.assign({}, associatedMetadata, get(row, ['config'], {}));
      collectionData = { ...COLLECTION_DATA, ...collectionData };
      const updatedCollection = { ...collectionData, ...{ items: collectionData.items.slice(0, initialLoadCount) } };
      return updatedCollection;
    }
  };
  const inspectorTitle = layout === 'Custom' ? 'Custom Ad' : 'Ad';
  switch (type) {
    case 'header':
      return (
        <RowWrapper
          index={index}
          hideActionButtons={true}
          type="header"
          currentRow={currentRow}
          key={`header-${index}`}
          isHeaderRow={true}
          enableDarkModePreview={enableDarkModePreview}
        >
          <AheadHeader />
        </RowWrapper>
      );
    case 'ads':
      return (
        <RowWrapper
          index={index}
          type="Ads"
          currentRow={currentRow}
          inspectorTitle={inspectorTitle}
          key={`comp-${index}`}
          isHeaderRow={isHeaderRow}
          enableDarkModePreview={enableDarkModePreview}
        >
          <AdComponent currentRow={currentRow} />
        </RowWrapper>
      );
    case 'story':
      const storyType = getStoryName(subPageType || '');
      return <StoryPage type={storyType} enableDarkModePreview={enableDarkModePreview} />;
    case 'widget':
      return (
        <RowWrapper
          index={index}
          type="widget"
          currentRow={currentRow}
          inspectorTitle="Widget"
          key={`comp-${index}`}
          isHeaderRow={isHeaderRow}
          enableDarkModePreview={enableDarkModePreview}
        >
          <WidgetComponent />
        </RowWrapper>
      );
    case 'intro':
      const isPageTypeAuthors = pageType === 'authors';
      const isPageTypePurchased = pageType === 'purchasedStoryPage';
      const propValue =
        pageType === 'tag' ? TAG_DATA : isPageTypeAuthors || isPageTypePurchased ? AUTHORS_DATA : AUTHOR_DATA;
      let introTitle = '';
      if (isPageTypeAuthors) {
        if (currentRow.layout === 'AuthorsListComponent') {
          introTitle = 'AUTHOR LIST ROW';
        } else {
          introTitle = 'AUTHOR LIST HEADER';
        }
      } else if (isPageTypePurchased) {
        if (currentRow.layout === 'PurchasePageIntroductionCard') {
          introTitle = 'PURCHASED STORIES HEADER';
        } else {
          introTitle = 'PURCHASED STORIES ROW';
        }
      }
      const IntroductionComponent = (window.QT_COMPONENTS && window.QT_COMPONENTS[layout]) || returnNull;
      const { enableLocalization = false, localizedElements = {} } = get(
        builderConfig,
        ['general', 'localization'],
        {}
      );
      let updatedConfig = { theme: backgroundColor };
      if (pageType === 'author') {
        const authorConfig = {
          customConnect:
            (enableLocalization && get(localizedElements, ['buttonLabels', 'connect'], 'Connect')) || 'Connect'
        };
        updatedConfig = {
          theme: backgroundColor,
          ...authorConfig
        };
      }

      if (isPageTypeAuthors || isPageTypePurchased) {
        updatedConfig = {
          ...currentRow.config,
          theme: enableDarkModePreview
            ? get(currentRow, ['config', 'darkTheme'], backgroundColor)
            : get(currentRow, ['config', 'theme'], backgroundColor)
        };
      }
      return (
        <RowWrapper
          index={index}
          type="intro"
          currentRow={currentRow}
          inspectorTitle={introTitle}
          key={`comp-${index}`}
          isHeaderRow={isHeaderRow}
          componentMeta={componentMeta}
          enableDarkModePreview={enableDarkModePreview}
        >
          <div data-test-id="introduction-card" className={styles.componentWrapper}>
            <IntroductionComponent {...currentRow} data={propValue} config={updatedConfig} pageType={pageType} />
          </div>
        </RowWrapper>
      );
    default:
      const loginPages = [
        'sign-in',
        'sign-up',
        'forgot-password',
        'email-otp',
        'mobile-otp',
        'reset-password',
        'enter-password'
      ];
      const getInspectorTitle = (pageType: string | undefined) => {
        switch (pageType) {
          case 'search':
            return 'Search Page Settings';
          case 'sign-in':
            return 'Sign in/ Sign up';
          case 'sign-up':
            return 'New account creation';
          case 'forgot-password':
            return 'Forgot Password';
          case 'email-otp':
            return 'Enter OTP (Email Verification)';
          case 'mobile-otp':
            return 'Enter OTP (Mobile)';
          case 'reset-password':
            return 'Reset Password';
          case 'enter-password':
            return 'Enter Password';
          case 'manage-subscriptions':
            return 'Manage Subscriptions';
          case 'available-plans':
            return 'Available Plans';
          case 'plan-change-overview':
            return 'Plan Change Overview';
          case 'profilePage':
            return 'My Profile Page Settings';
          default:
            return 'Row Settings';
        }
      };

      const inspectorSettingsTitle: string = getInspectorTitle(pageType);

      const renderComponent = () => {
        const ComponentName = (window.QT_COMPONENTS && window.QT_COMPONENTS[layout]) || returnNull;
        const pageTypes = ['author', 'tag'];
        const hideRowTitle = pageType && pageTypes.includes(pageType) ? { showRowTitle: false } : {};
        if (pageType === 'search') {
          const intialStoriesCount = get(
            builderConfig,
            ['search', 'pageSetting', 'searchResultsConfig', 'initialLoadCount'],
            6
          );
          const updatedData = {
            ...SEARCH_DATA,
            ...{ stories: SEARCH_DATA.stories.slice(0, intialStoriesCount) }
          };

          return (
            <ComponentName
              {...currentRow}
              data={updatedData}
              config={Object.assign(hideRowTitle, currentRow.config, rowsGeneralConfig)}
              builderConfig={builderConfig}
              data-id={index}
            />
          );
        } else if (pageType === 'magazine' && currentRow.layout === 'ArrowPageIntroductionCard') {
          return (
            <ComponentName
              {...currentRow}
              config={{
                ...MAGAZINE_INTRO_DATA,
                ...currentRow.config,
                theme: enableDarkModePreview
                  ? get(currentRow, ['config', 'darkTheme'], backgroundColor)
                  : get(currentRow, ['config', 'theme'], backgroundColor),
                rowsGeneralConfig
              }}
              data-id={index}
            />
          );
        } else if (pageType && loginPages.includes(pageType)) {
          return <Login pageType={pageType} enableDarkModePreview={enableDarkModePreview} />;
        } else if (currentRow.layout === 'ArrowMagazineEditions') {
          const pageType = configPath[1];
          const initialCount = get(currentRow, ['config', 'initialLoadCount'], '12');
          const setLimit = () => {
            switch (pageType) {
              case 'magazine-landing':
                return 8;
              case 'issue-landing':
                return 4;
              case 'archive':
                return initialCount;
            }
          };
          return (
            <ComponentName
              {...currentRow}
              collection={MAGAZINE_PAGE_DATA}
              config={{
                ...currentRow.config,
                rowsGeneralConfig,
                theme: enableDarkModePreview
                  ? get(currentRow, ['config', 'darkTheme'], backgroundColor)
                  : get(currentRow, ['config', 'theme'], backgroundColor)
              }}
              data-id={index}
              limit={setLimit()}
              showLoadmore={true}
            />
          );
        } else if (pageType === 'subscription') {
          return <Subscription subscriptionConfig={builderConfig} data={SUBSCRIPTION_DATA} />;
        } else if (
          pageType === 'manage-subscriptions' ||
          pageType === 'available-plans' ||
          pageType === 'plan-change-overview'
        ) {
          return <ManageSubscriptionAndPlans pageType={pageType} />;
        } else if (pageType === 'profilePage') {
          return <Profile config={currentRow.config} isDarkModeEnabled={enableDarkModePreview} />;
        } else {
          const collectionData = getFixtureData(currentRow, layout);
          const { enableLocalization = false, localizedElements = {} } = get(
            builderConfig,
            ['general', 'localization'],
            {}
          );
          const { timeToRead, months, meridiem, publishTime } = localizedElements;
          const localizationConfig = enableLocalization
            ? {
                localizedTimeToRead: timeToRead,
                localizedMonths: months,
                localizedMeridiem: meridiem,
                localizedPublishTime: publishTime
              }
            : {};
          return (
            <ComponentName
              {...currentRow}
              collection={collectionData}
              config={Object.assign(
                hideRowTitle,
                currentRow.config,
                {
                  theme: enableDarkModePreview
                    ? get(currentRow, ['config', 'darkTheme'], backgroundColor)
                    : get(currentRow, ['config', 'theme'], backgroundColor)
                },
                { numberOfStoriesToShow: initialLoadCount, initialLoadCount },
                rowsGeneralConfig,
                localizationConfig,
                { magazineTitle: 'Forbes' }
              )}
              data-id={index}
            />
          );
        }
      };
      const fetchStyle = () => {
        switch (pageType) {
          case 'manage-subscriptions':
            return `${styles.componentWrapper} ${styles.manageSubscriptionWrapper}`;
          case 'subscription':
            return styles.subscriptionWrapper;
          default:
            return styles.componentWrapper;
        }
      };
      return (
        <RowWrapper
          index={index}
          type="Collections"
          currentRow={currentRow}
          key={`comp-${index}`}
          componentMeta={componentMeta}
          inspectorTitle={inspectorSettingsTitle}
          enableDarkModePreview={enableDarkModePreview}
        >
          <div className={fetchStyle()}>{renderComponent()}</div>
        </RowWrapper>
      );
  }
};
