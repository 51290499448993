import produce from 'immer';
import get from 'lodash/get';
import {
  IConfig,
  IGeneralCommentSettings,
  IMeType,
  IRowDetails,
  ISelectedRow,
  IStorySlot
} from '../../../utils/interfaces';
import { getStoryStoreField, updatePageType } from '../../../utils/utils';
import {
  PAGEBUILDER_BREAKING_NEWS_UPDATE_THEME_ATTRIBUTES_KEY,
  PAGEBUILDER_BUILDER_UPDATE_CONFIG,
  PAGEBUILDER_CREATE_NEW_LANDING_PAGE,
  PAGEBUILDER_CREATE_NEW_STORY_PAGE,
  PAGEBUILDER_DARK_MODE_PB_PREVIEW,
  PAGEBUILDER_FOOTER_UPDATE_SOCIAL_LINK_STYLE,
  PAGEBUILDER_FOOTER_UPDATE_STYLE,
  PAGEBUILDER_FOOTER_UPDATE_THEME_ATTRIBUTES_KEY,
  PAGEBUILDER_GENERAL_ADD_FALLBACK_IMAGE,
  PAGEBUILDER_GENERAL_ADD_LOGO,
  PAGEBUILDER_GENERAL_AMP,
  PAGEBUILDER_GENERAL_DELETE_LOGO,
  PAGEBUILDER_GENERAL_OTHER_ADS_UPDATE_SCRIPTS,
  PAGEBUILDER_GENERAL_PREMIUM_STORY_ICON_STYLE,
  PAGEBUILDER_GENERAL_STORY_DEMARCATION_STYLE,
  PAGEBUILDER_GENERAL_SPONSORED_STORY_STYLE,
  PAGEBUILDER_GENERAL_REDIRECT_URL,
  PAGEBUILDER_STORY_GENERAL_SETTINGS,
  PAGEBUILDER_GENERAL_ROW_SECTION_TAG_STYLE,
  PAGEBUILDER_GENERAL_UPDATE_ACCESSTYPE_CONFIG,
  PAGEBUILDER_GENERAL_UPDATE_AD_SETTINGS,
  PAGEBUILDER_GENERAL_UPDATE_APP_DOWNLOAD_LINKS_SETTINGS,
  PAGEBUILDER_GENERAL_UPDATE_BREAKING_NEWS,
  PAGEBUILDER_GENERAL_UPDATE_COLORS_IN_PALETTE,
  PAGEBUILDER_GENERAL_UPDATE_COMMENT_PLUGIN_SETTINGS,
  PAGEBUILDER_GENERAL_UPDATE_CONFIG,
  PAGEBUILDER_GENERAL_UPDATE_CUSTOM_CODE_SETTINGS,
  PAGEBUILDER_GENERAL_UPDATE_CUSTOM_FONTS,
  PAGEBUILDER_GENERAL_UPDATE_FONTS,
  PAGEBUILDER_GENERAL_UPDATE_LOCALIZATION,
  PAGEBUILDER_GENERAL_UPDATE_NOTIFICATIONS,
  PAGEBUILDER_GENERAL_UPDATE_SPA,
  PAGEBUILDER_GENERAL_UPDATE_NEXT_HEADER_CONFIG,
  PAGEBUILDER_GENERAL_UPDATE_OTHER_CONFIG,
  PAGEBUILDER_GENERAL_UPDATE_ROW_TITLE_STYLE,
  PAGEBUILDER_GENERAL_UPDATE_ROW_TITLE_STYLE_ARROW,
  PAGEBUILDER_GENERAL_UPDATE_SINGLE_SIGN_ON,
  PAGEBUILDER_GENERAL_UPDATE_THIRD_PARTY_CONFIG,
  PAGEBUILDER_GENERAL_WIDGET,
  PAGEBUILDER_GENERATE_AD_SLOTS,
  PAGEBUILDER_HEADER_ADD_ROW_BOTTOM,
  PAGEBUILDER_HEADER_ADD_ROW_TOP,
  PAGEBUILDER_HEADER_DATE_TIME_SETTINGS,
  PAGEBUILDER_HEADER_DELETE_ROW,
  PAGEBUILDER_HEADER_UPDATE_SELECTED_ROW,
  PAGEBUILDER_HEADER_UPDATE_SELECTED_ROW_SETTINGS,
  PAGEBUILDER_HEADER_UPDATE_SOCIAL_LINK_STYLE,
  PAGEBUILDER_HEADER_UPDATE_STYLE,
  PAGEBUILDER_HEADER_UPDATE_THEME_ATTRIBUTES_KEY,
  PAGEBUILDER_INFINITE_SCROLL,
  PAGEBUILDER_ROW_ADD_ROW_BOTTOM,
  PAGEBUILDER_ROW_ADD_ROW_TOP,
  PAGEBUILDER_ROW_DELETE_ROW,
  PAGEBUILDER_ROW_MOVE,
  PAGEBUILDER_ROW_UPDATE_SELECTED_ROW,
  PAGEBUILDER_ROW_UPDATE_SELECTED_ROW_SETTINGS,
  PAGEBUILDER_SEARCH_ENABLE_ADVANCED_SEARCH,
  PAGEBUILDER_SEARCH_UPDATE_FILTER_OPTIONS,
  PAGEBUILDER_SEARCH_UPDATE_OTHER_SETTINGS,
  PAGEBUILDER_SET_UPDATED_PAGES,
  PAGEBUILDER_STORY_ELEMENTS_UPDATE_ATTRIBUTE,
  PAGEBUILDER_STORY_ELEMENTS_UPDATE_SETTINGS,
  PAGEBUILDER_STORY_PAGE_SETTINGS,
  PAGEBUILDER_STORY_SLOT_SETTING,
  PAGEBUILDER_STORY_UPDATE_ATTRIBUTE,
  PAGEBUILDER_STORY_UPDATE_AUTHOR_CARD,
  PAGEBUILDER_STORY_UPDATE_AUTHOR_CARD_OPTS,
  PAGEBUILDER_STORY_UPDATE_AUTHOR_STYLE,
  PAGEBUILDER_STORY_UPDATE_DATE_TIME,
  PAGEBUILDER_STORY_UPDATE_SLOTS,
  PAGEBUILDER_STORY_UPDATE_STYLES,
  PAGEBUILDER_UPDATE_CONFIG_VERSION,
  PAGEBUILDER_UPDATE_LANDING_PAGE_DETAILS,
  PAGEBUILDER_UPDATE_PAGE_SETTINGS,
  PAGEBUILDER_UPDATE_METERED_PAYWALL_SETTINGS,
  PAGEBUILDER_GENERAL_ADD_MAGAZINE_CONFIG,
  PAGEBUILDER_PERFORMANCE_BOT_MOD
} from './actions';
import { addRow, deleteRow } from './immerHelpers';
import { moveRow, updatePageSettings, updateSelectedRowLayout, updateSelectedRowSettings } from './immerHelpers/index';
import { getRowIndexToSlice } from './row-helper';

export const configInitialState: IConfig = {
  general: {
    nextConfig: {
      header: {
        backgroundColor: ''
      }
    },
    rows: { titleStyle: {} },
    logos: []
  },
  home: {},
  header: {
    primaryTextColor: '',
    primaryBackgroundColor: '',
    darkPrimaryTextColor: '',
    darkPrimaryBackgroundColor: '',
    darkSecondaryBackgroundColor: '',
    darkSecondaryTextColor: '',
    secondaryTextColor: '',
    secondaryBackgroundColor: '',
    enableAppDownloadLinksButton: false,
    enableSubscriptionButton: false,
    enableSSO: false,
    navigateHeaderLogo: 'homeUrl',
    hamburgerAndMegamenuBackgroundColor: '',
    hamburgerAndMegamenuDarkBackgroundColor: '',
    hamburgerAndMegamenuTextColor: '',
    hamburgerAndMegamenuDarkTextColor: '',
    logoUrl: ''
  },
  story: {},
  customStory: {},
  footer: {
    navigateFooterLogo: 'homeUrl',
    logoUrl: ''
  },
  section: {},
  author: {},
  authors: {},
  tag: {},
  landingPage: { layouts: {} },
  search: {},
  magazine: {},
  subscription: {},
  'manage-subscriptions': {},
  'available-plans': {},
  'plan-change-overview': {},
  login: {
    'sign-in': {},
    'sign-up': {},
    'forgot-password': {},
    'reset-password': {},
    'email-otp': {},
    'enter-password': {},
    'mobile-otp': {}
  },
  meteredPaywall: {},
  bannerSettings: {},
  updatedPages: [],
  version: -9999,
  enableDarkModePreview: false,
  configVersion: 0,
  redirectUrls: [],
  profilePage: {},
  purchasedStoryPage: {},
  performance: {
    bots: {
      gtm: false,
      adSense: false
    }
  }
};

const configReducer = (state: IConfig = configInitialState, action: { type: string; payload: any }) => {
  switch (action.type) {
    case PAGEBUILDER_BUILDER_UPDATE_CONFIG:
      // Making sure that somebody is not replacing full config accidently.
      // This is not a error proof, dev should have the responsibility of not replacing full config.
      const { header, general, home, customStory, landingPage } = get(action, ['payload'], {});
      if (!header || !general || !home) return state;
      // Need to remove this once all of our existing PB publishers move to header rows
      const headersConfig = {
        header: {
          ...(!state.header.rows && {
            rows: [
              {
                rowId: new Date().getTime(),
                type: 'header',
                layout: 'Header',
                layoutLabel: 'Header',
                config: {}
              }
            ]
          }),
          ...action.payload.header
        }
      };

      const tagConfig = {
        ...(!state.tag && {
          tag: {
            rows: [
              {
                rowId: 1588586775234,
                type: 'intro',
                layout: 'ArrowTagIntroductionCard',
                layoutLabel: 'Arrow: TagIntroductionCard',
                pageType: ''
              },
              {
                rowId: new Date().getTime(),
                type: 'collections',
                layout: 'ArrowThreeColGrid',
                layoutLabel: 'Arrow: ThreeColGrid',
                config: {
                  showRowTitle: false
                },
                pageType: ''
              }
            ]
          }
        })
      };

      const authorConfig = {
        ...(!state.author && {
          author: {
            rows: [
              {
                rowId: 1588586775234,
                type: 'intro',
                layout: 'ArrowAuthorIntroductionCard',
                layoutLabel: 'Arrow: AuthorIntroductionCard',
                pageType: ''
              },
              {
                rowId: new Date().getTime(),
                type: 'collections',
                layout: 'ArrowThreeColGrid',
                layoutLabel: 'Arrow: ThreeColGrid',
                config: {
                  showRowTitle: false
                },
                pageType: ''
              }
            ]
          }
        })
      };

      const authorsConfig = {
        ...(!state.authors && {
          authors: {
            rows: [
              {
                rowId: 1588586775234,
                type: 'intro',
                layout: 'ArrowPageIntroductionCard',
                layoutLabel: 'Arrow: AuthorPageIntroductionCard',
                config: {
                  pageTitle: 'Our Authors',
                  pageDescription:
                    'We recognize that our authors are the heart of what we do. We have dedicated authors for each genre and have numerous stories by them. You can go to any author and discover the stories from your favourite author.',
                  showButton: false
                },
                enableBorder: true,
                pageType: ''
              },
              {
                rowId: new Date().getTime(),
                type: 'collections',
                layout: 'AuthorsListComponent',
                layoutLabel: 'Authors List Component',
                config: {
                  initialLoadCount: 9,
                  subsequentLoadCount: 9,
                  enableBio: true,
                  enableSocialLinks: true
                },
                pageType: ''
              }
            ]
          }
        })
      };

      const magazineConfig = {
        ...(!state.magazine && {
          magazine: {
            'magazine-landing': {
              rows: [
                {
                  rowId: 1600957639831,
                  name: 'headerEdition',
                  layout: 'ArrowPageIntroductionCard',
                  layoutLabel: 'Arrow: PageIntroductionCard',
                  config: {
                    showButton: false,
                    buttonText: 'Subscribe',
                    enableBorder: true
                  },
                  pageSetting: {
                    bgColor: '#131922'
                  }
                },
                {
                  rowId: 1600957639838,
                  name: 'topStories',
                  layout: 'ArrowMagazineWidget',
                  layoutLabel: 'Arrow: MagazineWidget',
                  config: {
                    showRowTitle: true,
                    showButton: true,
                    buttonText: 'Go to Issue'
                  }
                },
                {
                  rowId: 1600957639848,
                  name: 'otherEditions',
                  layout: 'ArrowMagazineEditions',
                  layoutLabel: 'Arrow: MagazineEditions',
                  config: {
                    showRowTitle: true,
                    showButton: true,
                    buttonText: 'View All',
                    rowTitle: 'Other Issues',
                    initialLoadCount: 8,
                    editionNameFormat: 'magazineDate'
                  }
                }
              ]
            },
            'issue-landing': {
              rows: [
                {
                  rowId: 1600957639825,
                  name: 'headerEdition',
                  layout: 'ArrowMagazineHeaderCard',
                  layoutLabel: 'Arrow: MagazineHeaderCard',
                  config: {
                    showButton: false,
                    buttonText: 'Subscribe'
                  }
                },
                {
                  rowId: 1600957639826,
                  name: 'topStories',
                  layout: 'ArrowThreeColSevenStory',
                  layoutLabel: 'Arrow: Three Col Seven Story',
                  config: {
                    showButton: false,
                    customCollectionName: 'Top Articles'
                  }
                },
                {
                  rowId: 1600957639827,
                  name: 'otherEditions',
                  layout: 'ArrowMagazineEditions',
                  layoutLabel: 'Arrow: MagazineEditions',
                  config: {
                    showRowTitle: true,
                    showButton: true,
                    buttonText: 'View All',
                    sortBy: 'older-issues',
                    rowTitle: 'Other Issues',
                    initialLoadCount: 4,
                    editionNameFormat: 'magazineDate'
                  }
                },
                {
                  rowId: 1600957639828,
                  name: 'allStories',
                  layout: 'ArrowFourColGrid',
                  layoutLabel: 'Arrow: Four Col',
                  config: {
                    initialLoadCount: 8,
                    customCollectionName: 'All Stories',
                    buttonText: 'Load More'
                  }
                }
              ]
            },
            archive: {
              rows: [
                {
                  rowId: 1600957639840,
                  name: 'headerEdition',
                  layout: 'ArrowPageIntroductionCard',
                  layoutLabel: 'Arrow: PageIntroductionCard',
                  config: {
                    showButton: false,
                    buttonText: 'Subscribe',
                    enableBorder: true
                  },
                  pageSetting: {
                    bgColor: '#131922'
                  }
                },
                {
                  rowId: 1600957639829,
                  name: 'allEditions',
                  layout: 'ArrowMagazineEditions',
                  layoutLabel: 'Arrow: MagazineEditions',
                  config: {
                    showRowTitle: true,
                    showButton: true,
                    buttonText: 'Load more',
                    sortBy: 'latest-issues',
                    rowTitle: 'Archive',
                    editionNameFormat: 'magazineDate',
                    initialLoadCount: '12',
                    subsequentLoadCount: '8'
                  }
                }
              ]
            }
          }
        })
      };

      const subscriptionConfig = {
        ...(!state.subscription && {
          subscription: {
            rows: [
              {
                rowId: 1573720413476,
                type: '',
                pageType: 'subscription',
                layout: 'Lavish',
                layoutLabel: 'Lavish'
              }
            ],
            pageSetting: {
              descriptionTitle: "What's Included",
              pageDescription:
                'Unlock premium news content and stay informed with our exclusive subscription plan. Get access to in-depth stories and expert analysis.',
              pageTitle: 'Discover the Power of News Subscription Plans',
              showGroupImg: false,
              showPlanImg: false,
              subscribeButton: 'Subscribe Now'
            }
          }
        })
      };

      const manageSubscriptionsConfig = {
        ...(!state['manage-subscriptions'] && {
          'manage-subscriptions': {
            rows: [
              {
                rowId: 1707820224022,
                type: '',
                layout: 'ManageSubscriptions',
                layoutLabel: 'Manage Subscriptions',
                pageType: 'manage-subscriptions',
                config: {
                  pageTitle: 'Manage Subscriptions',
                  pageDescription:
                    'View your active and expired subscriptions, and effortlessly switch plans to suit your needs.',
                  activeSubscription_label: 'Active Subscription(s)',
                  activeSubscription_duration: 'DURATION',
                  activeSubscription_autoPayments: 'AUTO PAYMENTS',
                  autoPayOffTxt: 'OFF',
                  autoPayOnTxt: 'ON',
                  activeSubscription_changePlanBtn: 'Change Plan',
                  noSubscription_text: "You don't appear to have a subscription.",
                  expiredSubscription_label: 'Expired Subscription(s)',
                  expiredSubscription_expiredOn: 'Expired on'
                }
              }
            ],
            pageSetting: {
              manageSubscriptionsDropdownText: 'Manage Subscriptions',
              errorMessage: 'Something went wrong, please try after sometime.'
            }
          }
        })
      };
      const availablePlansConfig = {
        ...(!state['available-plans'] && {
          'available-plans': {
            rows: [
              {
                rowId: 1710048547281,
                type: '',
                layout: 'AvailablePlans',
                layoutLabel: 'Available Plans',
                pageType: 'available-plans',
                config: {
                  pageTitle: 'Available Plans',
                  pageDescription:
                    'Explore the available plans you can switch to. Find new options that perfectly match your needs and preferences.',
                  choosePlanBtn: 'Choose Plan',
                  showPlanImages: false,
                  note:
                    '^ Based on your current subscription, we have applied a prorated discount. The discount is applicable only for the first billing. From the next billing you’ll be charged the base price of the plan.'
                }
              }
            ]
          }
        })
      };

      const planChangeOverviewConfig = {
        ...(!state['plan-change-overview'] && {
          'plan-change-overview': {
            rows: [
              {
                rowId: 1710048575377,
                type: '',
                layout: 'PlanChangeOverview',
                layoutLabel: 'Plan Change Overview',
                pageType: 'plan-change-overview',
                config: {
                  pageTitle: 'Plan Change Overview',
                  pageDescription: 'Compare current and new subscription plans at a glance.',
                  currentPlanLabel: 'CURRENT PLAN',
                  newPlanLabel: 'NEW PLAN',
                  amountPayableLabel: 'Amount Payable ^',
                  proceedButton: 'Proceed',
                  note: `^ Based on your current subscription, we have applied a prorated discount. The discount is applicable only for the first billing. From the next billing you’ll be charged the base price of the plan.`
                }
              }
            ]
          }
        })
      };

      const profilePageConfig = {
        ...(!state.profilePage && {
          profilePage: {
            rows: [
              {
                rowId: 1573720413476,
                type: '',
                pageType: 'profile-page',
                layout: 'UserProfilePage',
                layoutLabel: 'UserProfilePage',
                config: {
                  pageTitle: 'My Profile',
                  nameInputLabel: 'Name',
                  emailInputLabel: 'Email',
                  saveButtonLabel: 'Save',
                  cancelButtonLabel: 'Discard Changes'
                }
              }
            ],
            pageSetting: {
              dropdownProfileText: 'My Profile'
            }
          }
        })
      };

      const purchasedStoryPageConfig = {
        ...(!state.purchasedStoryPage && {
          purchasedStoryPage: {
            rows: [
              {
                rowId: 1573720413476,
                type: 'intro',
                layout: 'PurchasePageIntroductionCard',
                layoutLabel: 'PageIntroductionCard',
                config: {
                  pageTitle: 'Purchased Stories',
                  pageDescription:
                    "View all the articles you've purchased on this page. Explore your collections and enjoy reading!"
                },
                pageType: ''
              },
              {
                rowId: 1600957639828,
                type: 'collections',
                name: 'allStories',
                layout: 'ArrowFourColGrid',
                layoutLabel: 'Purchased Stories Layout',
                config: {
                  initialLoadCount: 4,
                  buttonText: 'Load More',
                  footerButton: 'SubsequentLoadCount'
                }
              }
            ],
            pageSetting: {
              dropdownPurchasedText: 'Purchased Stories',
              emptyStoriesLabel: 'You’ve not purchased any stories yet.',
              redirectHomePage: 'Go to Home Page 123'
            }
          }
        })
      };

      // Prevent removal of customStory fields:
      // Sometimes (not consistently) updatePBConfig action overrides changes made after adding a
      // custom story template with an older version of the config, and the custom story field is
      // lost, showing a broken page. We are doing a spread override below to preserve that. If we
      // ever have the feature to delete story templates in PB, then this hack should be removed.

      const customStoryConfig = {
        customStory: {
          ...state.customStory,
          ...customStory
        }
      };

      const landingPageConfig = {
        ...landingPage,
        ...state.landingPage
      };

      const performanceConfig = {
        ...(!state['performance'] && {
          performance: {
            bots: {
              gtm: false,
              adSense: false
            }
          }
        })
      };

      return {
        ...state,
        ...(!action.payload.version && { version: 0 }),
        ...(action.payload as object),
        ...headersConfig,
        ...tagConfig,
        ...authorConfig,
        ...authorsConfig,
        ...magazineConfig,
        ...customStoryConfig,
        ...landingPageConfig,
        ...subscriptionConfig,
        ...manageSubscriptionsConfig,
        ...availablePlansConfig,
        ...planChangeOverviewConfig,
        ...profilePageConfig,
        ...purchasedStoryPageConfig,
        ...performanceConfig
      };

    case PAGEBUILDER_UPDATE_CONFIG_VERSION:
      return produce(state, (draft: any) => {
        draft.version = action.payload;
      });

    // General.
    case PAGEBUILDER_GENERAL_UPDATE_COLORS_IN_PALETTE:
      return produce(state, (draft: any) => {
        draft.general.colors = action.payload;
        draft.updatedPages = updatePageType('general', draft.updatedPages);
      });
    case PAGEBUILDER_GENERAL_UPDATE_FONTS:
      return produce(state, (draft: any) => {
        draft.general.fonts = { ...draft.general.fonts, ...(action.payload as object) };
        draft.updatedPages = updatePageType('general', draft.updatedPages);
      });

    case PAGEBUILDER_GENERAL_UPDATE_CUSTOM_FONTS:
      return produce(state, (draft: any) => {
        draft.general.fonts.customFonts = { ...draft.general.fonts.customFonts, ...(action.payload as object) };
        draft.updatedPages = updatePageType('general', draft.updatedPages);
      });

    case PAGEBUILDER_GENERAL_UPDATE_LOCALIZATION:
      const actionPayloadKey = Object.keys(action.payload)[0];
      const subLocalizedElements = [
        'months',
        'meridiem',
        'publishTime',
        'normalSubscription',
        'payPerArticle',
        'meteredPaywall',
        'magazineSubscription',
        'loginAccess',
        'paymentFailure',
        'paymentProcessing',
        'paymentSuccess',
        'shippingAddress',
        'buttonLabels',
        '404Page',
        'myProfile',
        'mySubscriptions',
        'purchasedStories',
        'signInSignUp',
        'searchLabels',
        'searchFilters',
        'emailVerification',
        'forgotPassword',
        'fieldErrors'
      ];
      return produce(state, (draft: any) => {
        if (actionPayloadKey.includes('enableLocalization')) {
          draft.general.localization = {
            ...draft.general.localization,
            ...(action.payload as object)
          };
        }
        if (subLocalizedElements.includes(actionPayloadKey)) {
          draft.general.localization = {
            ...draft.general.localization,
            localizedElements: {
              ...draft.general.localization.localizedElements,
              [actionPayloadKey]: {
                ...get(draft, ['general', 'localization', 'localizedElements', actionPayloadKey], {}),
                ...(action.payload[actionPayloadKey] as object)
              }
            }
          };
        } else {
          draft.general.localization = {
            ...draft.general.localization,
            localizedElements: {
              ...draft.general.localization.localizedElements,
              ...(action.payload as object)
            }
          };
        }
        draft.updatedPages = updatePageType('general', draft.updatedPages);
      });
    case PAGEBUILDER_GENERAL_ADD_LOGO:
      return produce(state, (draft: any) => {
        draft.general.logos = action.payload.map((item: string) => item.trim()).filter((item: string) => item);
        draft.updatedPages = updatePageType('general', draft.updatedPages);
      });
    case PAGEBUILDER_GENERAL_DELETE_LOGO:
      return produce(state, (draft: any) => {
        const positionOfLogo = draft.general.logos.indexOf(action.payload);
        draft.general.logos.splice(positionOfLogo, 1);
        draft.updatedPages = updatePageType('general', draft.updatedPages);
      });
    case PAGEBUILDER_GENERAL_ADD_FALLBACK_IMAGE:
      return produce(state, (draft: any) => {
        draft.general.fallbackImage = { ...draft.general.fallbackImage, ...(action.payload as object) };
        draft.updatedPages = updatePageType('general', draft.updatedPages);
      });
    // 'PAGEBUILDER_GENERAL_UPDATE_ROW_TITLE_STYLE_ARROW' & 'PAGEBUILDER_GENERAL_UPDATE_ROW_TITLE_STYLE'
    // are used to update the row title style of each for arrow and ahead respectively.
    case PAGEBUILDER_GENERAL_UPDATE_ROW_TITLE_STYLE:
      return produce(state, (draft: any) => {
        draft.general.rows = {
          ...draft.general.rows,
          titleStyle: {
            ...get(draft.general, ['rows', 'titleStyle'], {}),
            ...(action.payload as object)
          }
        };
        draft.updatedPages = updatePageType('general', draft.updatedPages);
      });
    case PAGEBUILDER_GENERAL_UPDATE_ROW_TITLE_STYLE_ARROW:
      return produce(state, (draft: any) => {
        draft.general.rows = {
          ...draft.general.rows,
          collectionNameTemplate: (action.payload as string) || get(draft.general, ['rows', 'collectionNameTemplate'])
        };
        draft.updatedPages = updatePageType('general', draft.updatedPages);
      });
    case PAGEBUILDER_GENERAL_UPDATE_NOTIFICATIONS:
      return produce(state, (draft: any) => {
        if (action.payload.type) {
          draft.general.notifications[action.payload.type] = {
            ...draft.general.notifications[action.payload.type],
            ...action.payload.config
          };
        } else {
          draft.general.notifications = {
            ...draft.general.notifications,
            ...action.payload.config
          };
        }
        draft.updatedPages = updatePageType('general', draft.updatedPages);
      });

    case PAGEBUILDER_GENERAL_ROW_SECTION_TAG_STYLE:
      return produce(state, (draft: any) => {
        draft.general.rows = {
          ...draft.general.rows,
          sectionTag: {
            ...get(draft.general, ['rows', 'sectionTag'], {}),
            ...action.payload
          }
        };
        draft.updatedPages = updatePageType('general', draft.updatedPages);
      });

    case PAGEBUILDER_GENERAL_PREMIUM_STORY_ICON_STYLE:
      return produce(state, (draft: any) => {
        draft.general.rows = {
          ...draft.general.rows,
          premiumStoryIcon: {
            ...get(draft.general, ['rows', 'premiumStoryIcon'], {}),
            ...action.payload
          }
        };
        draft.updatedPages = updatePageType('general', draft.updatedPages);
      });

    case PAGEBUILDER_GENERAL_STORY_DEMARCATION_STYLE:
      return produce(state, (draft: any) => {
        const { demarcationType, ...payload } = action.payload;
        if (demarcationType) {
          draft.general.rows = {
            ...draft.general.rows,
            [demarcationType]: {
              ...get(draft.general, ['rows', demarcationType], {}),
              ...payload
            }
          };
        } else {
          draft.general.rows = {
            ...draft.general.rows,
            ...payload
          };
        }
        draft.updatedPages = updatePageType('general', draft.updatedPages);
      });

    case PAGEBUILDER_GENERAL_SPONSORED_STORY_STYLE:
      return produce(state, (draft: any) => {
        draft.general.sponsoredStoryConfig = {
          ...draft.general.sponsoredStoryConfig,
          [action.payload.key]: action.payload.value
        };
      });

    case PAGEBUILDER_GENERAL_UPDATE_CUSTOM_CODE_SETTINGS:
      return produce(state, (draft: any) => {
        draft.general.tags = { ...state.general.tags, ...(action.payload as object) };
        draft.updatedPages = updatePageType('general', draft.updatedPages);
      });
    case PAGEBUILDER_GENERAL_REDIRECT_URL:
      return produce(state, (draft: any) => {
        draft.redirectUrls = action.payload;
        draft.updatedPages = updatePageType('general', draft.updatedPages);
      });
    case PAGEBUILDER_GENERAL_UPDATE_AD_SETTINGS:
      return produce(state, (draft: any) => {
        draft.general.ads = { ...state.general.ads, ...(action.payload as object) };
        draft.updatedPages = updatePageType('general', draft.updatedPages);
      });

    case PAGEBUILDER_GENERAL_UPDATE_CONFIG:
      return produce(state, (draft: any) => {
        draft.general = { ...state.general, ...action.payload };
        draft.updatedPages = updatePageType('general', draft.updatedPages);
      });

    case PAGEBUILDER_GENERAL_UPDATE_ACCESSTYPE_CONFIG:
      return produce(state, (draft: any) => {
        draft.general.accesstypeIntegration = {
          ...draft.general.accesstypeIntegration,
          ...(action.payload as object)
        };
        draft.updatedPages = updatePageType('general', draft.updatedPages);
      });

    case PAGEBUILDER_GENERAL_UPDATE_COMMENT_PLUGIN_SETTINGS:
      return produce(state, (draft: any) => {
        if (action.payload.meType) {
          draft.general.commentPlugin = {
            ...draft.general.commentPlugin,
            meType: {
              ...get(draft, ['general', 'commentPlugin', 'meType']),
              ...(action.payload.meType as IMeType)
            }
          };
        } else {
          draft.general.commentPlugin = {
            ...draft.general.commentPlugin,
            ...(action.payload as IGeneralCommentSettings)
          };
        }
        draft.updatedPages = updatePageType('general', draft.updatedPages);
      });

    case PAGEBUILDER_GENERAL_OTHER_ADS_UPDATE_SCRIPTS:
      return produce(state, (draft: any) => {
        draft.general.otherAdsScripts = { ...state.general.otherAdsScripts, ...(action.payload as object) };
        draft.updatedPages = updatePageType('general', draft.updatedPages);
      });
    case PAGEBUILDER_GENERAL_UPDATE_APP_DOWNLOAD_LINKS_SETTINGS:
      return produce(state, (draft: any) => {
        draft.general.appDownloadLinks = { ...state.general.appDownloadLinks, ...(action.payload as object) };
        draft.updatedPages = updatePageType('general', draft.updatedPages);
      });

    case PAGEBUILDER_GENERAL_UPDATE_THIRD_PARTY_CONFIG:
      return produce(state, (draft: any) => {
        const { type, config } = action.payload;
        const fixedValue = type === 'facebook-verification' ? { property: 'fb:pages' } : { name: type };
        if (type) {
          draft.general.thirdPartyIntegrations.customMetadata =
            draft.general.thirdPartyIntegrations.customMetadata || {};
          draft.general.thirdPartyIntegrations.customMetadata[type] = {
            ...draft.general.thirdPartyIntegrations.customMetadata[type],
            ...fixedValue,
            ...config
          };
        } else {
          draft.general.thirdPartyIntegrations = {
            ...draft.general.thirdPartyIntegrations,
            ...config
          };
        }
        draft.updatedPages = updatePageType('general', draft.updatedPages);
      });
    case PAGEBUILDER_GENERAL_AMP:
      return produce(state, (draft: any) => {
        if (action.payload.localizedElements) {
          draft.general.amp.localizedElements = {
            ...draft.general.amp.localizedElements,
            ...(action.payload.localizedElements as object)
          };
        } else {
          draft.general.amp = { ...draft.general.amp, ...(action.payload as object) };
        }
        draft.updatedPages = updatePageType('general', draft.updatedPages);
      });

    case PAGEBUILDER_GENERAL_UPDATE_OTHER_CONFIG:
      return produce(state, (draft: any) => {
        const { deletedDomain = '', whiteListedDomains = '' } = action.payload;
        if (deletedDomain) {
          const positionOfDomainItem = draft.general.others.whiteListedDomains.indexOf(deletedDomain);
          draft.general.others.whiteListedDomains.splice(positionOfDomainItem, 1);
          draft.general.others = { ...draft.general.others };
        } else if (whiteListedDomains) {
          draft.general.others = {
            ...draft.general.others,
            whiteListedDomains: whiteListedDomains.map((item: string) => item.trim()).filter((item: string) => item)
          };
        } else {
          draft.general.others = { ...draft.general.others, ...(action.payload as object) };
        }
      });

    case PAGEBUILDER_GENERAL_UPDATE_SPA:
      return produce(state, (draft: any) => {
        draft.general.serviceWorker = { ...draft.general.serviceWorker, ...(action.payload as object) };
        draft.updatedPages = updatePageType('general', draft.updatedPages);
      });

    case PAGEBUILDER_GENERAL_UPDATE_NEXT_HEADER_CONFIG:
      return produce(state, (draft: any) => {
        const config = draft.general?.nextConfig?.header || {};
        const updatedConfig = { header: { ...config, ...action.payload } };
        draft.general.nextConfig = { ...draft.general.nextConfig, ...updatedConfig };
        draft.updatedPages = updatePageType('general', draft.updatedPages);
      });

    case PAGEBUILDER_GENERAL_UPDATE_BREAKING_NEWS:
      return produce(state, (draft: any) => {
        draft.general.breakingNews = { ...draft.general.breakingNews, ...(action.payload as object) };
        draft.updatedPages = updatePageType('general', draft.updatedPages);
      });

    case PAGEBUILDER_GENERAL_UPDATE_SINGLE_SIGN_ON:
      return produce(state, (draft: any) => {
        draft.general.sso = { ...draft.general.sso, ...(action.payload as object) };
        draft.updatedPages = updatePageType('general', draft.updatedPages);
      });

    case PAGEBUILDER_GENERAL_WIDGET:
      return produce(state, (draft: any) => {
        draft.general.widget = { ...draft.general.widget, ...(action.payload as object) };
        draft.updatedPages = updatePageType('general', draft.updatedPages);
      });

    // Header
    case PAGEBUILDER_HEADER_UPDATE_STYLE:
      return produce(state, (draft: any) => {
        draft.header = draft.header || {};
        draft.header.headerStyle = action.payload as string;
        draft.updatedPages = updatePageType('header', draft.updatedPages);
      });

    case PAGEBUILDER_HEADER_UPDATE_SOCIAL_LINK_STYLE:
      return produce(state, (draft: any) => {
        draft.header.socialFollowStyle = action.payload as string;
        draft.updatedPages = updatePageType('header', draft.updatedPages);
      });

    case PAGEBUILDER_HEADER_UPDATE_THEME_ATTRIBUTES_KEY:
      return produce(state, (draft: any) => {
        if (action.payload.navigateHeaderLogo === 'homeUrl') {
          draft.header = { ...draft.header, ...(action.payload as object), logoUrl: '' };
        } else {
          draft.header = { ...draft.header, ...(action.payload as object) };
        }
        draft.updatedPages = updatePageType('header', draft.updatedPages);
      });

    case PAGEBUILDER_HEADER_DATE_TIME_SETTINGS:
      return produce(state, (draft: any) => {
        draft.header.dateTime = { ...state.header.dateTime, ...(action.payload as object) };
        draft.updatedPages = updatePageType('header', draft.updatedPages);
      });

    // Header Rows
    case PAGEBUILDER_HEADER_ADD_ROW_TOP:
      return produce(state, (draft: any) => {
        draft.header = draft.header || {};
        const updateIndex = getRowIndexToSlice({
          rowsList: draft.header.rows as ISelectedRow[],
          rowDetails: action.payload as IRowDetails
        });
        draft.header.rows = [
          ...get(draft, ['header', 'rows'], []).slice(0, updateIndex),
          get(action.payload, 'row'),
          ...get(draft, ['header', 'rows'], []).slice(updateIndex)
        ];
        draft.updatedPages = updatePageType('header', draft.updatedPages);
      });
    case PAGEBUILDER_HEADER_ADD_ROW_BOTTOM:
      return produce(state, (draft: any) => {
        const updateIndex = getRowIndexToSlice({
          rowsList: draft.header.rows as ISelectedRow[],
          rowDetails: action.payload as IRowDetails,
          down: true
        });

        draft.header.rows = [
          ...get(draft, ['header', 'rows'], []).slice(0, updateIndex),
          get(action.payload, 'row'),
          ...get(draft, ['header', 'rows'], []).slice(updateIndex)
        ];
        draft.updatedPages = updatePageType('header', draft.updatedPages);
      });
    case PAGEBUILDER_HEADER_DELETE_ROW:
      return produce(state, (draft: any) => {
        const updateIndex = getRowIndexToSlice({
          rowsList: draft.header.rows as ISelectedRow[],
          rowDetails: action.payload as IRowDetails
        });
        get(draft, ['header', 'rows'], []).splice(updateIndex, 1);
        draft.header.rows = get(draft, ['header', 'rows'], []);
        draft.updatedPages = updatePageType('header', draft.updatedPages);
      });

    case PAGEBUILDER_HEADER_UPDATE_SELECTED_ROW:
      return produce(state, (draft: any) => {
        draft.header.rows =
          state.header.rows &&
          state.header.rows.map((rowData: ISelectedRow) => {
            if (rowData.rowId === action.payload.selectedRowId) {
              return action.payload.row;
            }
            return rowData;
          });
        draft.updatedPages = updatePageType('header', draft.updatedPages);
      });
    case PAGEBUILDER_HEADER_UPDATE_SELECTED_ROW_SETTINGS:
      return produce(state, (draft: any) => {
        draft.header.rows =
          state.header.rows &&
          state.header.rows.map((rowData: ISelectedRow) => {
            if (rowData.rowId === action.payload.selectedRowId) {
              return { ...rowData, config: { ...(rowData.config || {}), ...action.payload.row } };
            }
            return rowData;
          });
        draft.updatedPages = updatePageType('header', draft.updatedPages);
      });

    case PAGEBUILDER_GENERAL_ADD_MAGAZINE_CONFIG:
      const defaultMagazineConfig = {
        'magazine-landing': {
          rows: [
            {
              rowId: 1600957639831,
              name: 'headerEdition',
              layout: 'ArrowPageIntroductionCard',
              layoutLabel: 'Arrow: PageIntroductionCard',
              config: {
                showButton: false,
                buttonText: 'Subscribe',
                enableBorder: true
              },
              pageSetting: {
                bgColor: '#131922'
              }
            },
            {
              rowId: 1600957639838,
              name: 'topStories',
              layout: 'ArrowMagazineWidget',
              layoutLabel: 'Arrow: MagazineWidget',
              config: {
                showRowTitle: true,
                showButton: true,
                buttonText: 'Go to Issue'
              }
            },
            {
              rowId: 1600957639848,
              name: 'otherEditions',
              layout: 'ArrowMagazineEditions',
              layoutLabel: 'Arrow: MagazineEditions',
              config: {
                showRowTitle: true,
                showButton: true,
                buttonText: 'View All',
                rowTitle: 'Other Issues',
                initialLoadCount: 8,
                editionNameFormat: 'magazineDate'
              }
            }
          ]
        },
        'issue-landing': {
          rows: [
            {
              rowId: 1600957639825,
              name: 'headerEdition',
              layout: 'ArrowMagazineHeaderCard',
              layoutLabel: 'Arrow: MagazineHeaderCard',
              config: {
                showButton: false,
                buttonText: 'Subscribe'
              }
            },
            {
              rowId: 1600957639826,
              name: 'topStories',
              layout: 'ArrowThreeColSevenStory',
              layoutLabel: 'Arrow: Three Col Seven Story',
              config: {
                showButton: false,
                customCollectionName: 'Top Articles'
              }
            },
            {
              rowId: 1600957639827,
              name: 'otherEditions',
              layout: 'ArrowMagazineEditions',
              layoutLabel: 'Arrow: MagazineEditions',
              config: {
                showRowTitle: true,
                showButton: true,
                buttonText: 'View All',
                sortBy: 'older-issues',
                rowTitle: 'Other Issues',
                initialLoadCount: 4,
                editionNameFormat: 'magazineDate'
              }
            },
            {
              rowId: 1600957639828,
              name: 'allStories',
              layout: 'ArrowFourColGrid',
              layoutLabel: 'Arrow: Four Col',
              config: {
                initialLoadCount: 8,
                customCollectionName: 'All Stories',
                buttonText: 'Load More'
              }
            }
          ]
        },
        archive: {
          rows: [
            {
              rowId: 1600957639840,
              name: 'headerEdition',
              layout: 'ArrowPageIntroductionCard',
              layoutLabel: 'Arrow: PageIntroductionCard',
              config: {
                showButton: false,
                buttonText: 'Subscribe',
                enableBorder: true
              },
              pageSetting: {
                bgColor: '#131922'
              }
            },
            {
              rowId: 1600957639829,
              name: 'allEditions',
              layout: 'ArrowMagazineEditions',
              layoutLabel: 'Arrow: MagazineEditions',
              config: {
                showRowTitle: true,
                showButton: true,
                buttonText: 'Load more',
                sortBy: 'latest-issues',
                rowTitle: 'Archive',
                editionNameFormat: 'magazineDate',
                initialLoadCount: '12',
                subsequentLoadCount: '8'
              }
            }
          ]
        }
      };
      return produce(state, (draft: any) => {
        if (action.payload.enableMagazine) {
          draft.magazine = {
            ...defaultMagazineConfig,
            ...draft.magazine,
            ...(action.payload as object)
          };
        } else {
          draft.magazine = {
            ...draft.magazine,
            ...(action.payload as object)
          };
        }
      });

    // Builder. (Reducer for Home, Section, Landing pages common actions)
    case PAGEBUILDER_ROW_ADD_ROW_TOP:
      return addRow(state, action, 'up');
    case PAGEBUILDER_ROW_ADD_ROW_BOTTOM:
      return addRow(state, action, 'down');
    case PAGEBUILDER_ROW_DELETE_ROW:
      return deleteRow(state, action);
    case PAGEBUILDER_ROW_MOVE:
      return moveRow(state, action);
    case PAGEBUILDER_ROW_UPDATE_SELECTED_ROW:
      return updateSelectedRowLayout(state, action);
    case PAGEBUILDER_ROW_UPDATE_SELECTED_ROW_SETTINGS:
      return updateSelectedRowSettings(state, action);
    case PAGEBUILDER_UPDATE_PAGE_SETTINGS:
      return updatePageSettings(state, action);

    // Footer
    case PAGEBUILDER_FOOTER_UPDATE_STYLE:
      return produce(state, (draft: any) => {
        draft.footer = draft.footer || {};
        draft.footer.footerStyle = action.payload as string;
        draft.updatedPages = updatePageType('footer', draft.updatedPages);
      });
    case PAGEBUILDER_FOOTER_UPDATE_SOCIAL_LINK_STYLE:
      return produce(state, (draft: any) => {
        draft.footer.socialFollowStyle = action.payload as string;
        draft.updatedPages = updatePageType('footer', draft.updatedPages);
      });
    case PAGEBUILDER_FOOTER_UPDATE_THEME_ATTRIBUTES_KEY:
      return produce(state, (draft: any) => {
        if (action.payload.navigateFooterLogo === 'homeUrl') {
          draft.footer = { ...draft.footer, ...(action.payload as object), logoUrl: '' };
        } else {
          draft.footer = { ...draft.footer, ...(action.payload as object) };
        }
        draft.updatedPages = updatePageType('footer', draft.updatedPages);
      });

    // breaking news
    case PAGEBUILDER_BREAKING_NEWS_UPDATE_THEME_ATTRIBUTES_KEY:
      return produce(state, (draft: any) => {
        draft.breakingNews = { ...draft.breakingNews, ...(action.payload as object) };
        draft.updatedPages = updatePageType('breakingNews', draft.updatedPages);
      });

    // Story
    case PAGEBUILDER_STORY_UPDATE_ATTRIBUTE:
      return produce(state, (draft: any) => {
        const storyStoreField = getStoryStoreField(action.payload.type);
        draft[storyStoreField] = draft[storyStoreField] || {};
        draft[storyStoreField][action.payload.type] = {
          ...draft[storyStoreField][action.payload.type],
          ...(action.payload.data as object)
        };
        draft.updatedPages = updatePageType(storyStoreField, draft.updatedPages);
      });

    case PAGEBUILDER_STORY_UPDATE_AUTHOR_STYLE:
      return produce(state, (draft: any) => {
        draft.story.authorStyle = action.payload as string;
        draft.updatedPages = updatePageType('story', draft.updatedPages);
      });

    case PAGEBUILDER_INFINITE_SCROLL:
      return produce(state, (draft: any) => {
        draft.infiniteScroll = { ...draft.infiniteScroll, ...(action.payload as object) };
        draft.updatedPages = updatePageType('infiniteScroll', draft.updatedPages);
      });

    // Search Page
    case PAGEBUILDER_SEARCH_ENABLE_ADVANCED_SEARCH:
      return produce(state, (draft: any) => {
        draft.search.pageSetting = { ...draft.search.pageSetting, ...action.payload };
        draft.updatedPages = updatePageType('search', draft.updatedPages);
      });

    case PAGEBUILDER_SEARCH_UPDATE_FILTER_OPTIONS:
      return produce(state, (draft: any) => {
        draft.search = draft.search || { pageSetting: {} };
        draft.search.pageSetting = {
          ...draft.search.pageSetting,
          filterOptions: { ...get(draft, ['search', 'pageSetting', 'filterOptions']), ...action.payload }
        };
        draft.updatedPages = updatePageType('search', draft.updatedPages);
      });

    case PAGEBUILDER_SEARCH_UPDATE_OTHER_SETTINGS:
      return produce(state, (draft: any) => {
        draft.search = draft.search || { pageSetting: {} };
        draft.search.pageSetting = {
          ...draft.search.pageSetting,
          searchResultsConfig: {
            ...get(draft, ['search', 'pageSetting', 'searchResultsConfig']),
            ...action.payload
          }
        };
        draft.updatedPages = updatePageType('search', draft.updatedPages);
      });

    case PAGEBUILDER_STORY_ELEMENTS_UPDATE_ATTRIBUTE:
      return produce(state, (draft: any) => {
        draft.story.globalStoryElements = draft.story.globalStoryElements || {};
        draft.story.globalStoryElements[action.payload.type] = {
          ...draft.story.globalStoryElements[action.payload.type],
          ...(action.payload.data as object)
        };
        draft.updatedPages = updatePageType('story', draft.updatedPages);
      });

    case PAGEBUILDER_STORY_ELEMENTS_UPDATE_SETTINGS:
      return produce(state, (draft: any) => {
        draft.story.globalStoryElements = draft.story.globalStoryElements || {};
        draft.story.globalStoryElements[action.payload.type] =
          draft.story.globalStoryElements[action.payload.type] || {};
        draft.story.globalStoryElements[action.payload.type][action.payload.settingsType] = {
          ...draft.story.globalStoryElements[action.payload.type][action.payload.settingsType],
          ...(action.payload.data as object)
        };
        draft.updatedPages = updatePageType('story', draft.updatedPages);
      });

    case PAGEBUILDER_STORY_UPDATE_STYLES:
      return produce(state, (draft: any) => {
        const storyStoreField = getStoryStoreField(action.payload.type);
        draft[storyStoreField] = draft[storyStoreField] || {};
        draft[storyStoreField][action.payload.type].settings = {
          ...draft[storyStoreField][action.payload.type].settings,
          ...(action.payload as object)
        };
        draft.updatedPages = updatePageType(storyStoreField, draft.updatedPages);
      });
    case PAGEBUILDER_STORY_UPDATE_AUTHOR_CARD:
      return produce(state, (draft: any) => {
        const storyStoreField = getStoryStoreField(action.payload.type);
        draft[storyStoreField] = draft[storyStoreField] || {};
        draft[storyStoreField][action.payload.type] = draft[storyStoreField][action.payload.type] || {};
        draft[storyStoreField][action.payload.type].settings =
          draft[storyStoreField][action.payload.type].settings || {};
        draft[storyStoreField][action.payload.type].settings.authorDetails = {
          ...draft[storyStoreField][action.payload.type].settings.authorDetails,
          ...(action.payload as object)
        };
        draft.updatedPages = updatePageType(storyStoreField, draft.updatedPages);
      });
    case PAGEBUILDER_STORY_PAGE_SETTINGS:
      return produce(state, (draft: any) => {
        const storyStoreField = getStoryStoreField(action.payload.type);
        draft[storyStoreField] = draft[storyStoreField] || {};
        draft[storyStoreField][action.payload.type] = draft[storyStoreField][action.payload.type] || {};
        draft[storyStoreField][action.payload.type].pageSettings = {
          ...draft[storyStoreField][action.payload.type].pageSettings,
          ...(action.payload as object)
        };
        draft.updatedPages = updatePageType(storyStoreField, draft.updatedPages);
      });
    case PAGEBUILDER_STORY_UPDATE_DATE_TIME:
      return produce(state, (draft: any) => {
        const storyStoreField = getStoryStoreField(action.payload.type);
        draft[storyStoreField] = draft[storyStoreField] || {};
        draft[storyStoreField][action.payload.type] = draft[storyStoreField][action.payload.type] || {};
        draft[storyStoreField][action.payload.type].settings =
          draft[storyStoreField][action.payload.type].settings || {};
        draft[storyStoreField][action.payload.type].settings.publishedDetails = {
          ...draft[storyStoreField][action.payload.type].settings.publishedDetails,
          ...(action.payload as object)
        };
        draft.updatedPages = updatePageType(storyStoreField, draft.updatedPages);
      });

    case PAGEBUILDER_STORY_UPDATE_AUTHOR_CARD_OPTS:
      return produce(state, (draft: any) => {
        const storyStoreField = getStoryStoreField(action.payload.type);
        draft[storyStoreField] = draft[storyStoreField] || {};
        draft[storyStoreField][action.payload.type] = draft[storyStoreField][action.payload.type] || {};
        draft[storyStoreField][action.payload.type].settings =
          draft[storyStoreField][action.payload.type].settings || {};
        draft[storyStoreField][action.payload.type].settings.authorDetails.opts = {
          ...draft[storyStoreField][action.payload.type].settings.authorDetails.opts,
          ...(action.payload as object)
        };
        draft.updatedPages = updatePageType(storyStoreField, draft.updatedPages);
      });

    case PAGEBUILDER_CREATE_NEW_STORY_PAGE:
      return produce(state, (draft: any) => {
        draft.customStory = draft.customStory || {};
        if (!draft.customStory[action.payload.storyPageName]) {
          draft.customStory[action.payload.storyPageName] = {
            rows: [
              {
                rowId: 1603877816293,
                type: 'story',
                config: {},
                pageType: 'story'
              }
            ],
            template: action.payload.templateName,
            storyType: action.payload.storyType
          };
        }
        draft.updatedPages = updatePageType('customStory', draft.updatedPages);
      });

    // Slots inside story and Aside collection

    case PAGEBUILDER_STORY_UPDATE_SLOTS:
      return produce(state, (draft: any) => {
        const storyStoreField = getStoryStoreField(action.payload.type);
        draft[storyStoreField] = draft[storyStoreField] || {};
        draft[storyStoreField][action.payload.type] = draft[storyStoreField][action.payload.type] || {};
        draft[storyStoreField][action.payload.type].slots = action.payload.slots;
        draft.updatedPages = updatePageType(storyStoreField, draft.updatedPages);
      });

    case PAGEBUILDER_STORY_SLOT_SETTING:
      return produce(state, (draft: any) => {
        const storyStoreField = getStoryStoreField(action.payload.type);
        draft[storyStoreField] = draft[storyStoreField] || {};
        draft[storyStoreField][action.payload.type] = draft[storyStoreField][action.payload.type] || {};
        draft[storyStoreField][action.payload.type].slots = draft[storyStoreField][action.payload.type].slots || [];
        const slots = [...draft[storyStoreField][action.payload.type].slots];

        draft[storyStoreField][action.payload.type].slots = slots.map((slot: IStorySlot) => {
          const slotId = Object.keys(slot)[0];
          const slotConfig = slot[slotId];
          if (slotId === action.payload.id) {
            return {
              [slotId]: {
                ...slotConfig,
                [action.payload.key]: action.payload.value
              }
            };
          }
          return { ...slot };
        });
        draft.updatedPages = updatePageType(storyStoreField, draft.updatedPages);
      });

    case PAGEBUILDER_GENERATE_AD_SLOTS:
      return produce(state, (draft: any) => {
        const storyStoreField = getStoryStoreField(action.payload.type);
        draft[storyStoreField] = draft[storyStoreField] || {};
        draft[storyStoreField][action.payload.type] = {
          ...draft[storyStoreField][action.payload.type],
          ...action.payload.value
        };
        draft.updatedPages = updatePageType(storyStoreField, draft.updatedPages);
      });

    // Landing Pages
    case PAGEBUILDER_CREATE_NEW_LANDING_PAGE:
      return produce(state, (draft: any) => {
        draft.landingPage = draft.landingPage || {};
        draft.landingPage.layouts = {
          ...draft.landingPage.layouts,
          [action.payload.landingPageId]: {
            rows: [],
            layoutName: action.payload.landingPageName,
            pageSetting: {
              layoutDataType: 'storiesAndCollections'
            }
          }
        };
        draft.updatedPages = updatePageType('landingPage', draft.updatedPages);
      });
    case PAGEBUILDER_UPDATE_LANDING_PAGE_DETAILS:
      return produce(state, (draft: any) => {
        draft.landingPage.layouts[action.payload.pageId] = {
          ...draft.landingPage.layouts[action.payload.pageId],
          ...(action.payload.pageDetails as object)
        };
        draft.updatedPages = updatePageType('landingPage', draft.updatedPages);
      });

    case PAGEBUILDER_SET_UPDATED_PAGES:
      return produce(state, (draft: any) => {
        draft.updatedPages = action.payload;
      });

    case PAGEBUILDER_DARK_MODE_PB_PREVIEW:
      return produce(state, (draft: any) => {
        draft.enableDarkModePreview = action.payload;
      });

    case PAGEBUILDER_UPDATE_METERED_PAYWALL_SETTINGS:
      const { meterStatus, readerType, storyBehind, wallType, key, value } = action.payload;
      return produce(state, (draft: any) => {
        if (meterStatus === 'meteringOff') {
          switch (storyBehind) {
            case 'login':
              draft.meteredPaywall.pageSetting = {
                ...draft.meteredPaywall.pageSetting,
                meteringOff: {
                  ...draft.meteredPaywall.pageSetting?.meteringOff,
                  login: { ...draft.meteredPaywall.pageSetting?.meteringOff?.login, [key]: value }
                }
              };
              break;
            case 'subscription':
              draft.meteredPaywall.pageSetting = {
                ...draft.meteredPaywall.pageSetting,
                meteringOff: {
                  ...draft.meteredPaywall.pageSetting?.meteringOff,
                  subscription: { ...draft.meteredPaywall.pageSetting?.meteringOff?.subscription, [key]: value }
                }
              };
              break;
          }
        } else if (meterStatus === 'meteringOn') {
          switch (readerType) {
            case 'anonymous':
              if (storyBehind === 'login') {
                draft.meteredPaywall.pageSetting = {
                  ...draft.meteredPaywall.pageSetting,
                  meteringOn: {
                    ...draft.meteredPaywall.pageSetting?.meteringOn,
                    anonymous: {
                      ...draft.meteredPaywall.pageSetting?.meteringOn?.anonymous,
                      login: {
                        ...draft.meteredPaywall.pageSetting?.meteringOn?.anonymous?.login,
                        [key]: value
                      }
                    }
                  }
                };
              }
              if (storyBehind === 'subscription') {
                if (key === 'AT_wallType') {
                  draft.meteredPaywall.pageSetting = {
                    ...draft.meteredPaywall.pageSetting,
                    meteringOn: {
                      ...draft.meteredPaywall.pageSetting?.meteringOn,
                      anonymous: {
                        ...draft.meteredPaywall.pageSetting?.meteringOn?.anonymous,
                        subscription: {
                          ...draft.meteredPaywall.pageSetting?.meteringOn?.anonymous?.subscription,
                          [key]: value
                        }
                      }
                    }
                  };
                }
                if (wallType === 'payWall') {
                  draft.meteredPaywall.pageSetting = {
                    ...draft.meteredPaywall.pageSetting,
                    meteringOn: {
                      ...draft.meteredPaywall.pageSetting?.meteringOn,
                      anonymous: {
                        ...draft.meteredPaywall.pageSetting?.meteringOn?.anonymous,
                        subscription: {
                          ...draft.meteredPaywall.pageSetting?.meteringOn?.anonymous?.subscription,
                          payWall: {
                            ...draft.meteredPaywall.pageSetting?.meteringOn?.anonymous?.subscription?.payWall,
                            [key]: value
                          }
                        }
                      }
                    }
                  };
                }
                if (wallType === 'loginWall') {
                  draft.meteredPaywall.pageSetting = {
                    ...draft.meteredPaywall.pageSetting,
                    meteringOn: {
                      ...draft.meteredPaywall.pageSetting?.meteringOn,
                      anonymous: {
                        ...draft.meteredPaywall.pageSetting?.meteringOn?.anonymous,
                        subscription: {
                          ...draft.meteredPaywall.pageSetting?.meteringOn?.anonymous?.subscription,
                          loginWall: {
                            ...draft.meteredPaywall.pageSetting?.meteringOn?.anonymous?.subscription?.loginWall,
                            [key]: value
                          }
                        }
                      }
                    }
                  };
                }
              }
              break;
            case 'loggedIn':
              draft.meteredPaywall.pageSetting = {
                ...draft.meteredPaywall.pageSetting,
                meteringOn: {
                  ...draft.meteredPaywall.pageSetting?.meteringOn,
                  loggedIn: { ...draft.meteredPaywall.pageSetting?.meteringOn?.loggedIn, [key]: value }
                }
              };
              break;
          }
        } else {
          draft.meteredPaywall.pageSetting = { ...draft.meteredPaywall.pageSetting, [key]: value };
        }
        draft.updatedPages = updatePageType('meteredPaywall', draft.updatedPages);
      });

    case PAGEBUILDER_STORY_GENERAL_SETTINGS:
      return produce(state, (draft: any) => {
        draft.story.pageSetting = {
          ...draft.story.pageSetting,
          ...(action.payload as object)
        };
      });

    case PAGEBUILDER_PERFORMANCE_BOT_MOD:
      return produce(state, (draft: any) => {
        draft.performance.bots = {
          ...draft.performance.bots,
          ...(action.payload as object)
        };
      });
    default:
      return state;
  }
};

export { configReducer };
