import { useEffect } from 'react';
import { initChameleon } from './chameleon';
import { getUserDetails } from '../../utils/utils';
import { useParams } from 'react-router-dom';

const Chameleon = () => {
  const { publisherId = '', domain = '' } = useParams();
  useEffect(() => {
    (async () => {
      initChameleon(document, window);
      await waitForChameleonLoad();
      const userDetails = await getUserDetails(publisherId);
      const { email, userId, uid_hash } = userDetails;
      if (email && userId && publisherId) {
        // @ts-ignore
        chmln.identify(userId, {
          uid_hash,
          email,
          project: domain,
          name: publisherId,
          company: {
            uid: 'quintype-pb',
            name: 'page-builder'
          }
        });
      }
    })();
  }, [domain, publisherId]);

  return null;
};

async function waitForChameleonLoad() {
  return new Promise(resolve => {
    const myInterval = setInterval(() => {
      try {
        // @ts-ignore
        if (typeof window.chmln?.identify === 'function') {
          resolve(true);
          clearInterval(myInterval);
        }
      } catch (err) {
        console.log('waiting for the property identify to be loaded on window.chmln', err);
      }
    }, 100);
  });
}

export default Chameleon;
