export const initChameleon = function(d: Document, w: Window & { [key: string]: any }) {
  const t = 'SfDhNzWubfgGqBAvRGNKKbTnP1HYsquqTuudYEd8fBRFuZ-1EXR5E-Av382t7djdCKvZO6';
  const c = 'chmln';
  const m = ['identify', 'alias', 'track', 'clear', 'set', 'show', 'on', 'off', 'custom', 'help', '_data'];
  const i = d.createElement('script');

  if (!w[c]) w[c] = {} as Record<string, any>;

  if (!w[c].root) {
    w[c].accountToken = t;
    w[c].location = w.location.href.toString();
    w[c].now = new Date();

    for (const method of m) {
      const methodArray: any[] = [];
      w[c][`${method}_a`] = methodArray;

      w[c][method] = function(...args: any[]) {
        methodArray.push(args);
      };
    }

    i.src = `https://fast.trychameleon.com/messo/${t}/messo.min.js`;
    i.async = true;
    d.head.appendChild(i);
  }
};
